<template>
  <b-modal v-model="showExitIntentModal" :can-cancel="false" :width="600">
    <div class="exit-modal">
      <div class="exit-modal__body">
        <div class="exit-modal__image"></div>

        <div class="exit-modal__text">
          <p class="is-size-4 has-text-weight-bold">
            {{ $t("af:exit_intent.title") }}
          </p>

          <div class="mb-6">
            <div class="usp is-flex is-align-items-center my-2">
              <div class="usp__icon mr-2">
                <b-icon
                  icon-pack="fa"
                  icon="check-circle"
                  type="is-grey-icon"
                ></b-icon>
              </div>

              <span class="has-text-grey-icon">{{
                $t("af:exit_intent.subtitle.first_line")
              }}</span>
            </div>

            <div class="usp is-flex is-align-items-center my-2">
              <div class="usp__icon mr-2">
                <b-icon
                  icon-pack="fa"
                  icon="check-circle"
                  type="is-grey-icon"
                ></b-icon>
              </div>

              <span class="has-text-grey-icon">{{
                $t("af:exit_intent.subtitle.second_line")
              }}</span>
            </div>
          </div>

          <b-button
            v-if="cbrVisible"
            type="is-green"
            class="has-text-weight-medium"
            expanded
            @click="openCallMeBackModal"
            >{{ $t("af:exit_intent.button.call_me_back") }}
          </b-button>
        </div>
      </div>

      <div class="close-btn cursor-pointer" @click="close">
        <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import exitIntent from "exit-intent";
import eventBus, { EVENTS } from "@/utils/eventBus";
import { CALL_ME_BACK_TYPE, UI_CONFIGS } from "@/utils/const";
import { mapGetters } from "vuex";

export default {
  name: "ExitIntentModal",
  data() {
    return {
      showExitIntentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    cbrVisible() {
      return this.$route.meta.mortgage
        ? this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST_MORTGAGE) ===
            "true"
        : this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST) === "true";
    },
  },
  methods: {
    openCallMeBackModal() {
      eventBus.$emit(
        EVENTS.OPEN_CALL_ME_BACK_MODAL,
        CALL_ME_BACK_TYPE.EXIT_INTENT
      );
      this.close();
    },
    close() {
      this.showExitIntentModal = false;
    },
  },
  mounted() {
    exitIntent({
      threshold: 0,
      maxDisplays: 1,
      eventThrottle: 100,
      onExitIntent: () => {
        this.showExitIntentModal = true;
      },
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.exit-modal {
  width: 80%;
  background: white;
  border-radius: 24px;
  position: relative;
  margin: auto;

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__image {
    min-height: 30vh;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/images/background-sport-car.png");
    width: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &__text {
    width: 100%;
    padding: 32px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mbh {
  .exit-modal__image {
    background-position: 0 0;
    background-size: cover;
    background-image: url("../assets/images/mbh_exit_mobile.png");
  }
}

@media only screen and (min-width: 769px) {
  .exit-modal {
    width: 100%;

    &__body {
      display: flex;
      flex-direction: row;
    }

    &__image {
      border-bottom-left-radius: 24px;
      border-top-right-radius: 0;
      width: 40%;
    }

    &__text {
      padding: 48px !important;
      width: 60%;
    }
  }

  .mbh {
    .exit-modal__image {
      background-position: -20px -25px;
      background-size: initial;
      background-image: url("../assets/images/mbh_exit_desktop.png");
    }
  }
}
</style>
